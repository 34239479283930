import './css/styles.css';
import * as BABYLON from "babylonjs";
import 'babylonjs-loaders';
import { AppManager } from './scripts/Managers/appManager.js';

let canvas = document.getElementById("renderCanvas");
let scene, engine, appManager = null;
//let isIPhone = false;

document.addEventListener("DOMContentLoaded", checkURL);

async function checkURL(){
    var url = new URL(window.location.href);
    var exp = url.searchParams.get("exp");

    if(exp)
        fetchTourData(exp);
    else
        fetchTourData("ML-QC_Training");
}

function fetchTourData(exp){
    fetch("https://km7sy4ll.api.sanity.io/v1/data/query/production?query=*%5B_type%20%3D%3D%20'experience'%20%26%26%20title%20%3D%3D%20%22"+exp+"%22%5D%7B%0A%20%20title%2C%0A%20%20tagLine%2C%0A%20%20camera-%3E%7B%0A%20%20%20%20cameraName%2C%0A%20%20%7D%2C%0A%20%20targetPart%2C%0A%20%20procedures%5B%5D-%3E%7B%0A%20%20%20%20%09title%2C%0A%20%20%20%20%09steps%5B%5D-%3E%7B%0A%20%20%20%20%20%20%20%20stepDescription%2CstepDescriptionSubTitle%2C%0A%20%20%20%20%20%20%20%20start%2Cend%2CtipDescription%2C%0A%20%20%20%20%20%20%20%20partsToHighlight%2C%0A%20%20%20%20%20%20%20%20inputRequired-%3E%7B%0A%20%20%20%20%20%20%20%20%20%20title%2C%0A%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20camera-%3E%7B%0A%20%20%20%20%20%20%20%20cameraName%2C%0A%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%7D%2C%0A%20%20modelURL%7Basset-%3E%7Burl%7D%7D%0A%7D%0A%0A")
    .then(response => response.json())
    .then(data => startGame(data))
}

async function startGame(data){
    canvas = document.getElementById("renderCanvas");
    engine = new BABYLON.Engine(canvas,true);
    scene = await createScene(data.result[0]);
    modifySettings();
    engine.runRenderLoop(function (){
        scene.render()
    });  
    //checkIfIPhone();
}

var createScene = async function (dataResult) {
    scene = new BABYLON.Scene(engine);
    appManager = new AppManager(scene, engine, dataResult);
    window.appManager = appManager;
    BABYLON.SceneLoader.ShowLoadingScreen = false;
    
    return scene;
};

function modifySettings()
{
    //document.getElementById("temp").style.display = "block";
    
    window.addEventListener("orientationchange", function() {
        appManager.uiManager.tryCreateUI();
    }, false);

    document.addEventListener('fullscreenchange', function() {
        appManager.uiManager.tryCreateUI();
    }, false);

    window.addEventListener("resize", function () {
        engine.resize();
    });
}

/* function checkIfIPhone(){
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIos() && !isInStandaloneMode()) {
        isIPhone = true;
        document.getElementById("popup").style.visibility = "visible";
        document.getElementById("InstallButton").style.visibility = "hidden";
    }

    document.getElementById("CloseButtoniOS").addEventListener('click', async () => {
        document.getElementById("popup").style.visibility = "hidden";
    });
} */
